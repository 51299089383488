export function estimateVideoMemoryRequirements(videoElement: HTMLVideoElement) {
    return new Promise((resolve) => {

      const calculateMemory = () => {

        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;
        
        const duration = videoElement.duration;
        
        const bytesPerFrame = width * height * 4;

        const estimatedBufferFrames = 30; 
        const bufferMemory = bytesPerFrame * estimatedBufferFrames;
        
        const decoderMemoryMultiplier = 1.5;
        const decoderMemory = bytesPerFrame * decoderMemoryMultiplier;
        
        const totalMemoryEstimate = bufferMemory + decoderMemory;
        
        resolve({
          width,
          height,
          duration,
          bytesPerFrame: bytesPerFrame,
          estimatedMemoryInBytes: totalMemoryEstimate,
          estimatedMemoryInMB: totalMemoryEstimate / (1024 * 1024),
          note: "This is a rough estimate. Actual memory usage depends on browser implementation, codec, and playback settings."
        });
      };
      

      if (videoElement.readyState >= 1) { 
        calculateMemory();
      } else {
        videoElement.addEventListener('loadedmetadata', calculateMemory, { once: true });
      }
    });
  }

  