import styles from "./global-dialog.module.scss";
import WModalCard, { iModalCard } from "../../components/modal-card/modal-card";
import WButton, { iButtonProps } from "../../components/button/button";
import { WDialog } from "../../components/dialog/dialog";

export interface IWGlobalDialog {
  dialogs: Array<{
    title?: string;
    description?: string;
    buttons?: Array<iButtonProps>;
    isDialogVisible: boolean;
    props?: iModalCard;
    onClose: () => any;
  }>;
}

const WGlobalDialog = ({ dialogs }: IWGlobalDialog) => {
  return (
    <div className={styles["container"]}>
      {dialogs.map(
        (
          { title, description, buttons, isDialogVisible, props, onClose },
          index
        ) => (
          <WDialog
            open={isDialogVisible}
            key={index}
            onClose={onClose}
            sx={{
              "& .MuiDialog-paper": {
                minWidth: "30vw",
                height: "30vh",
              },
            }}
          >
            <WModalCard {...props} className="h-full">
              <div className="h-full w-full flex flex-col gap-3 justify-around items-center">
                {title && <h3 className="flex flex-1 justify-center items-center"> {title} </h3>}
                {description && ( <p className="flex flex-1"> {description} </p>)}
                {buttons && (
                  <div className="w-full flex gap-2">
                    {buttons.map((button, index) => (
                      <>
                        {button.children && (
                          <WButton
                            key={index}
                            {...button}
                            borderRadius="5px"
                            className="flex-1"
                          >
                            {button.children}
                          </WButton>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>
            </WModalCard>
          </WDialog>
        )
      )}
    </div>
  );
};

export default WGlobalDialog;
